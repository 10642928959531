import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-042cc041"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "back-btn" }
const _hoisted_2 = { class: "back-btn__txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: _ctx.url }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}